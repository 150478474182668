const dev_mode = false;

const BASE_URL = dev_mode
  ? "http://localhost:3001/"
  : "https://tsf-server.azurewebsites.net/";

const WEB_URL = "http://localhost:3000/";

const CDN = "https://tsf.azureedge.net/thestudyfalcon/";

const Server = {
  URL: BASE_URL,
  WEB_URL,
  dev_mode,

  // // // // // LANDING SCREENS // // // // //
  LAND_ALL_CAT: `${BASE_URL}u/getAllCat`,
  LAND_ALL_EXAMS: `${BASE_URL}u/getAllExams`,
  USRDASHBOARD: `${BASE_URL}u/usrDash`,
  SUPPORT_MAIL: `${BASE_URL}u/usrSprtMail`,
  NEW_EU: `${BASE_URL}u/newExm`,
  // // // // END OF LANDING SCREENS // // // //

  // // // // // //  Auth Routes // // // // // //
  ALUSR: `${BASE_URL}u/getAlusr`,
  REQOTP: `${BASE_URL}u/reqOtp`,
  UREG: `${BASE_URL}u/usrReg`,
  ULGOIN: `${BASE_URL}u/usrLogin`,
  UCHECK: `${BASE_URL}u/chUser`,
  UPUSER: `${BASE_URL}u/upUser`,
  NOTI_READ: `${BASE_URL}u/notRead`,
  GET_USR_IMG: `${BASE_URL}u/getUsrImg`,
  // // // // // End of Auth Routes // // // // //

  // // // // // // // AI Routes // // // // // //
  UAISUBS: `${BASE_URL}u/usrAiSubs`,
  UAIATMPT: `${BASE_URL}u/usrAiAttempt`,
  UPRELQUES: `${BASE_URL}u/prelimQues`,
  UPRELQSAVE: `${BASE_URL}u/prelimQSave`,
  UPREREPORT: `${BASE_URL}u/prelimReport`,
  TODOSKIP: `${BASE_URL}u/todoSkip`,
  UPREADPROG: `${BASE_URL}u/upReadProg`,
  DETEVALREP: `${BASE_URL}u/detailedReport`,
  TODOGIVEN: `${BASE_URL}u/givenTodo`,
  // // // // //  End of AI Routes  // // // // //

  // // // // // // // Courses // // // // // // //
  UCRS: `${BASE_URL}u/getUsrCrs`,
  SETCRS: `${BASE_URL}u/setUsrCrs`,
  CSUBS: `${BASE_URL}u/crsSubs`,
  UCRS_UP: `${BASE_URL}u/usrCrsUpdate`,
  REMCRS: `${BASE_URL}u/remCrs`,
  // // // // // // End of Courses // // // // // //

  // // // // // Subscription Routes // // // // //
  SUBCONTENT: `${BASE_URL}u/subContent`,
  CRORDER: `${BASE_URL}U/createOrder`,
  RAZOR_SDK: `https://checkout.razorpay.com/v1/checkout.js`,
  USUB: `${BASE_URL}u/userSub`,
  USRLIMIT: `${BASE_URL}u/usrLimit`,
  // // // // End of Subscription Routes // // // //

  // // // // // Mock-Test Routes // // // // //
  MOCKTEST: `${BASE_URL}u/mockTest`,
  MOCKSUBMIT: `${BASE_URL}u/mockSubmit`,
  MOCKRPRT: `${BASE_URL}u/mockReport`,
  // // // // End of Mock-Test Routes // // // //

  // // // // Practice-Test Routes // // // // //
  PRACEXAM: `${BASE_URL}u/pracExam`,
  PRACSAVE: `${BASE_URL}u/pracSave`,
  PRACREPORT: `${BASE_URL}u/pracReport`,
  PRTSTPROG: `${BASE_URL}u/pracAnalysis`,
  QUESRPT: `${BASE_URL}u/quesReport`,
  // // // End of Practice-Test Routes // // //

  // // // // E-Book Routes // // // // // // //
  GETEB: `${BASE_URL}u/getEbook`,
  GETPDF: `${BASE_URL}u/getPdf`,
  LAND_ALL_EBOOK: `${BASE_URL}u/getAllEbooks`,
  GETUSREBK: `${BASE_URL}u/getUsrEbook`,
  CMDEBOOK: `${BASE_URL}u/cmdEbook`,
  // // // // End of E-Book Routes // // // //

  // // // // MVP Routes // // // // // // //
  MVP: `${BASE_URL}u/mvpDash`,
  FREEVIDTHUMB: `${BASE_URL}u/getFreeVidThumb/`,
  TPCVIDTHUMB: `${BASE_URL}u/getTpcVidThumb/`,
  GETVIDINFO: `${BASE_URL}u/getVidInfo`,
  GETEDUINFO: `${BASE_URL}u/getEduInfo`,
  GETTPCVIDS: `${BASE_URL}u/getTpcVids`,
  VIDREAD: `${BASE_URL}u/vidRead`,
  REMVIDREAD: `${BASE_URL}u/remVidRead`,
  EDUDP: `${BASE_URL}u/eduDp`,
  VIDSTR: `${BASE_URL}u/mvpStream`,
  // // // // End of MVP Routes // // // // //

  // // // // Current Affair Routes // // // //
  PODCAST: `${BASE_URL}u/AllPodcast`,
  GETPODCAST: `${BASE_URL}u/getPodcast`,
  PIB: `${BASE_URL}u/allPib`,
  DNA: `${BASE_URL}u/allDna`,
  CA: `${BASE_URL}u/allCa`,
  GET_DESC: `${BASE_URL}u/getNewsDesc`,
  CA_QUES: `${BASE_URL}u/getCaQuestions`,
  USR_BM: `${BASE_URL}u/getUsrBookmarks`,
  REM_BM: `${BASE_URL}u/rmBookmark`,
  ADD_BM: `${BASE_URL}u/addCaBm`,
  GETNAIMG: `${BASE_URL}u/getNwsImg`,
  GETINSHORTS: `${BASE_URL}u/getInshortsNews`,
  // // // // End of Current Affair Routes // //

  // // // // Resource URL // // // //
  EbGenre: `${CDN}Ebooks/genre/`,
  EbTHUMB: `${CDN}Ebooks/thumb/`,
  QUESTHUMB: `${CDN}quesJpg/`,
  EXAMINFO: `${CDN}exam_info/`,
  CANEWSIMAGE: `${CDN}nwsImg/`,
  TPCPDF: `${CDN}topics/`,
  EBOOK_SRC: `${CDN}Ebooks/pdf/`,
  EB_GNR_SRC: `${CDN}Ebooks/genre/`,
  CACAT_THUMB_SRC: `${CDN}nwsGnrImg//`,
  PIB_SRC: `${CDN}pib/`,
  DNA_SRC: `${CDN}dna/`,
  MAG_SRC: `${CDN}magazines/`,
  EU_SRC: `${CDN}euInfo/`,
  EU_NOTI_SRC: `${CDN}euNoti/`,
  CATIMG: `${CDN}catImg/`,
  NAGNR: `${CDN}nwsGnrImg/`,
  POD_SRC: `${CDN}podcasts/`,
  // // // //  Resource URL // // // //
};

export default Server;
